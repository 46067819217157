<template>
  <div>
    <nav>
      <a
        href="#"
        :class="{'isActive': year === 2018}"
        @click="setYear(2018)"
      >
        2018
      </a>
      |
      <a
        href="#"
        :class="{'isActive': year === 2020}"
        @click="setYear(2020)"
      >
        2020
      </a>
    </nav>
    <header class="heading">
      <h1>{{ headline }}</h1>
    </header>
    <div class="main">
      <keep-alive>
        <transition
          name="ballmove"
          enter-active-class="animated zoomIn"
          leave-active-class="animated fadeOutDown"
          mode="out-in"
        >
          <component :is="activePlayer" />
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import LockdownPlayer from "@/components/LockdownPlayer";
import NoelEnJazzPlayer from "@/components/NoelEnJazzPlayer";

export default {
  name: 'Calendar',
  data: () => {
    return {
      year: 2020
    }
  },
  computed: {
    activePlayer: function () {
      return (this.year === 2020) ? LockdownPlayer : NoelEnJazzPlayer
    },
    headline: function () {
      return (this.year === 2020) ? "The Lockdown Sessions" : "Noël en Jazz"
    },
  },
  components: {LockdownPlayer},
  methods: {
    setYear: function (year) {
      document.dispatchEvent(new Event("pauseAudio"));

      this.year = year;
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    font-family: "Yanone Kaffeesatz", sans-serif;
    padding-top: 30px;

    a {
      padding: 15px;
      font-size: 1.3rem;
      font-weight: 400;
      text-decoration: none;
    }
  }

  .isActive {
    font-weight: bold;
    text-decoration: underline;
  }

  h1 {
    text-shadow: 2px 3px 4px #000;
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-weight: 200;
  }

  .main {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    max-width: 100%;
  }
</style>
