<template>
  <Player :music-playlist="playlist" />
</template>

<script>
import Player from "@/components/Player";

export default {
  name: "NoelEnJazzPlayer",
  components: {
    Player
  },
  data: () => {
    return {
      playlist: [
        {
          title: "Silent Night",
          artist: "Moritz Wachter & Jana Tichauer",
          url: require("@/assets/audio/2018/01 Silent Night.mp3"),
          image: require("@/assets/images/2018/silent-night.jpeg")
        },
        {
          title: "Adeste Fideles",
          artist: "Moritz Wachter & Jana Tichauer",
          url: require("@/assets/audio/2018/02 Adeste Fideles.mp3"),
          image: require("@/assets/images/2018/adeste.jpeg")
        },
        {
          title: "Joy To The World",
          artist: "Moritz Wachter & Jana Tichauer",
          url: require("@/assets/audio/2018/03 Joy to the world.mp3"),
          image: require("@/assets/images/2018/joy.jpeg")
        },
        {
          title: "Happy XMas",
          artist: "Moritz Wachter & Jana Tichauer",
          url: require("@/assets/audio/2018/04 Happy Xmas.mp3"),
          image: require("@/assets/images/2018/happy-xmas.jpeg")
        },
        {
          title: "Auld Lang Syne",
          artist: "Moritz Wachter",
          url: require("@/assets/audio/2018/05 Auld lang syne.mp3"),
          image: require("@/assets/images/2018/auld-lang-syne.jpeg")
        },
        {
          title: "God Rest Ye Merry, Gentlemen",
          artist: "Moritz Wachter & Jana Tichauer",
          url: require("@/assets/audio/2018/06 God rest ye merry gentlemen.mp3"),
          image: require("@/assets/images/2018/god-rest-ye.jpeg")
        },
        {
          title: "Sind die Lichter angezündet",
          artist: "Jana Tichauer",
          url: require("@/assets/audio/2018/07 Sind die Lichter angezündet.wav"),
          image: require("@/assets/images/2018/sind-die-lichter.jpeg")
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>