<template>
  <Player :music-playlist="playlist" />
</template>

<script>
import Player from "@/components/Player";

export default {
  name: "LockdownPlayer",
  components: {
    Player
  },
  data: () => {
    return {
      playlist: [
        {
          title: "Es ist ein Ros entsprungen",
          artist: "Jana Tichauer",
          url: require("@/assets/audio/2020/es-ist-ein-ros.mp3"),
          image: require("@/assets/images/2020/es-ist-ein-ros.jpeg")
        },
        {
          title: "Schneeflöckchen Weißröckchen",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/schneeflöckchen.mp3"),
          image: require("@/assets/images/2020/schneeflöckchen.jpeg")
        },
        {
          title: "Adeste Fideles",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/adeste.mp3"),
          image: require("@/assets/images/2020/adeste.jpeg")
        },
        {
          title: "O Du Fröhliche",
          artist: "Jana Tichauer",
          url: require("@/assets/audio/2020/oh-du-fröhliche.mp3"),
          image: require("@/assets/images/2020/oh-du-fröhliche.jpeg")
        },
        {
          title: "God Rest Ye Merry, Gentlemen",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/god-rest-ye.mp3"),
          image: require("@/assets/images/2020/god-rest-ye.jpeg")
        },
        {
          title: "Medley",
          artist: "Moritz Wachter",
          url: require("@/assets/audio/2020/medley.mp3"),
          image: require("@/assets/images/2020/medley.jpeg")
        },
        {
          title: "Sind die Lichter angezündet",
          artist: "Jana Tichauer",
          url: require("@/assets/audio/2020/sind-die-lichter.mp3"),
          image: require("@/assets/images/2020/sind-die-lichter.jpeg")
        },
        {
          title: "Stille Nacht",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/silent-night.mp3"),
          image: require("@/assets/images/2020/silent-night.jpeg")
        },
        {
          title: "Maria durch ein Dornwald ging",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/maria.mp3"),
          image: require("@/assets/images/2020/maria.jpeg")
        },
        {
          title: "O Tannenbaum",
          artist: "Jana Tichauer & Moritz Wachter",
          url: require("@/assets/audio/2020/oh-tannenbaum.mp3"),
          image: require("@/assets/images/2020/oh-tannenbaum.jpeg")
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>